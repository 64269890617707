
import { defineComponent } from "vue";
import Input from "../components/Input.vue";

export default defineComponent({
  name: "Calculator",
  components: {
    Input
  }
});
