
import { defineComponent } from "vue";
import Calculator from "./ui/Calculator.vue";
import Calculation from "./ui/Calculation.vue";
import Footer from "./ui/Footer.vue";

export default defineComponent({
  name: "App",

  components: {
    Calculator,
    Calculation,
    Footer
  },

  data() {
    return {
      //
    };
  }
});
